import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';
import filter from "lodash/filter";
import forEach from "lodash/forEach";

export default function(store) {
  const state = {
    items: [],
    loaded: false,
    context: null,
  };

  const actions = {
    "blocks.get": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/blocks/').then((result) => {
          store.commit('blocks.setData', result.data);
          store.commit('blocks.setContext', {type: 'eventDiscipline', id: opts.eventDisciplineId })
          joinRooms()
          resolve(result);
        }, err => reject(err));
      });
    },
    "blocks.getSession": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/sessions/' + opts.sessionId + '/blocks/').then((result) => {
          store.commit('blocks.setData', result.data);
          store.commit('blocks.setContext', {type: 'session', id: opts.sessionId })
          resolve(result);
        }, err => reject(err));
      });
    },
    "blocks.saveOrders": function(ctx, opts) {
      const data = []
      forEach(opts.prepBlocks, block => {
        let blockData = {
          blockId: block.block.id,
          parts: []
        }
        // add participants, maintain order
        forEach(block.parts, part => {
          let item = {}
          if (part.filler) {
            item.filler = true
          } else {
            item.participationId = part.part.id
            // add exerciseTypeId when needed
            if (part.exerciseTypeId) {
              item.exerciseTypeId = part.exerciseTypeId
            }
          }
          blockData.parts.push(item)
        });
        data.push(blockData)
      });
      return Vue.http.post(config.root + '/sessions/' + opts.sessionId + '/setBlockParticipations',
        { data, append: !! opts.append })
    },
    "blocks.saveRotationOrder": function(ctx, opts) {
      return Vue.http.patch(config.root + '/blocks/' + opts.blockId, {exerciseOrders: opts.data});
    },
  };

  const mutations = {
    "blocks.setData": function(state, data) {
      Vue.set(state, 'items', data);
      Vue.set(state, 'loaded', true);
    },
    "blocks.setContext": function(state, context) {
      Vue.set(state, 'context', context)
    },
    "block.add": function(state, block) {
      state.items.push(block);
    },
    "block.update": function(state, block) {
      const index = state.items.findIndex(i => i.id === block.id);
      let blocks = state.items;
      blocks.splice(index, 1, block);
      Vue.set(state, 'items', blocks);
    },
    "block.remove": function(state, id) {
      const items = filter(state.items, item => {
        return item.id !== id;
      });
      Vue.set(state, 'items', items);
    }
  };

  function leaveRooms() {
    if (state.context) {
      socket.emit('leave', 'block:' + state.context.id)
    }
  }

  function joinRooms() {
    if (state.context?.id) {
      socket.emit('join', 'block:' + state.context.id)
    }
  }

  function listener() {
    socket.on('connect', function () {
      joinRooms()
    })
    socket.on('block.update', function(result) {
      if (result.type === "remove") {
        //remove not supported
        return;
      }

      if (result.type === 'update') {
        store.commit('block.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('block.add', result.data);
      }
    });
    socket.on('block.delete', result => {
      store.commit('block.remove', result.data);
    });
    socket.emit('join', 'block.delete');
  }

  store.registerModule('blocks', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}

