import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  let state = {
    items: [],
    eventId: undefined
  };

  const actions = {
    "teams.get": function(ctx, opts) {
      if (! opts.eventId) {
        return null;
      }

      if (ctx.state.eventId) {
        socket.emit('leave', 'team:' + ctx.state.eventId);
      }

      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/events/' + opts.eventId + '/teams').then(result => {
          store.commit('teams.setItems', result.data);
          store.commit('teams.setEventId', opts.eventId);
          joinRooms()
          resolve();
        }, err => reject(err));
      });
    },
    "team.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/teams/' + opts.teamId, opts.data).then(result => {
          store.commit('team.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "team.add": function(ctx, opts) {
      opts.data.eventId = ctx.state.eventId;
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/events/' + ctx.state.eventId + '/teams', opts.data).then(result => {
          store.commit('team.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    "team.remove": function(state, opts) {
      store.commit('team.remove', opts.teamId);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/teams/' + opts.teamId).then(() => {
          resolve();
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "teams.setEventId": function(state, eventId) {
      Vue.set(state, 'eventId', eventId);
    },
    "teams.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "team.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      })
    },
    "team.update": function(state, team) {
      const index = state.items.findIndex(i => i.id === team.id);
      const teams = state.items;
      if (index >= 0) {
        teams.splice(index, 1, team);
      }
      else {
        teams.push(team);
      }
      Vue.set(state, 'items', teams);
    }
  };

  function joinRooms() {
    if (state.eventId) {
      socket.emit('join', 'team:' + state.eventId)
    }
  }

  function listener() {
    socket.on('connect', function () {
      joinRooms()
    })
    socket.on('team.update', function(result) {
      if (result.type === "remove") {
        store.commit('team.remove', result.where.id);
        return;
      }

      store.commit('team.update', result.data);
    });
    socket.on('team.delete', result => {
      store.commit('team.remove', result.data);
    });
    socket.emit('join', 'team.delete');
  }

  store.registerModule('teams', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
