import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    eventDisciplineId: undefined,
    items: [],
    loaded: false
  };
  const actions = {
    "participations.get": function(ctx, opts) {
      if (ctx.state.eventDisciplineId) {
        socket.emit('leave', 'participation:' + ctx.state.eventDisciplineId);
      }

      return new Promise((resolve, reject) => {
        store.commit("participations.setEventDiscipline", opts.eventDisciplineId);
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/participations').then((result) => {
          store.commit('participations.setItems', result.data);
          setRooms()
          resolve(result);
        }, error => { reject(error); });
      });
    },
    "participation.save": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.patch(config.root + '/participations/' + opts.data.id, opts.data).then((result) => {
          store.commit('participation.update', result.data);
          resolve(result.data);
        }, error => { reject(error); });
      });
    },
    "participation.add": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/eventDisciplines/' + ctx.state.eventDisciplineId + '/participations', opts.data)
          .then((result) => {
          store.commit('participation.update', result.data);
          resolve(result.data);
        }, error => { reject(error); });
      });
    },
    "participation.remove": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/participations/' + opts.data.id).then(() => {
          store.commit('participation.remove', opts.data.id);
          resolve();
        }, error => { reject(error); });
      });
    },
    "participation.setStatus": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/participations/' + opts.participationId + '/updateStatus',
          {roundIndex: opts.roundIndex, newStatus: opts.status, force: opts.force }).then(result => {
          resolve(result);
        }, error => { reject(error); });
      });
    }
  };

  const mutations = {
    "participations.setEventDiscipline": function(state, eventDisciplineId) {
      Vue.set(state, "eventDisciplineId", eventDisciplineId);
    },
    "participations.setItems": function(state, data) {
      Vue.set(state, 'items', data);
      Vue.set(state, 'loaded', true);
    },
    "participation.update": function(state, data) {
      const participations = filter(state.items, function(item) {
        return item.id !== data.id;
      });
      participations.push(data);
      Vue.set(state, 'items', participations);
    },
    "participation.remove": function(state, id) {
      const participations = filter(state.items, function(item) {
        return item.id !== id;
      });
      Vue.set(state, 'items', participations);
    }
  };

  function setRooms() {
    if (state.eventDisciplineId) {
      socket.emit('join', 'participation:' + state.eventDisciplineId)
    }
  }

  function listener() {
    socket.on('connect', function () {
      setRooms()
    })
    socket.on('participation.update', function(result) {
      if (result.type === 'update') {
        store.commit('participation.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('participation.update', result.data);
      }
    });
    socket.on('participation.delete', result => {
      store.commit('participation.remove', result.data);
    });
    socket.emit('join', 'participation.delete');
  }

  store.registerModule('participations', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}

