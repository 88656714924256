import Vue from 'vue';
import socket from 'client/socket';
import config from 'client/config';
import filter from "lodash/filter";

export default function(store) {
  const state = {
    context: null,
    items: [],
    loaded: false
  };

  const actions = {
    "blockParticipations.get": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit('blockParticipations.setContext', { type: 'eventDiscipline', id: opts.eventDisciplineId })
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/blockParticipations')
          .then((result) => {
          store.commit('blockParticipations.setItems', result.data);
          joinRooms()

          resolve(result);
        }, err => reject(err));
      });
    },
    "blockParticipations.getSession": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit('blockParticipations.setContext', { type: 'session', id: opts.sessionId })
        Vue.http.get(config.root + '/sessions/' + opts.sessionId + '/blockParticipations').then((result) => {
          store.commit('blockParticipations.setItems', result.data);
          joinRooms()

          resolve(result);
        }, err => reject(err));
      });
    },
    "blockParticipations.getBlock": function(ctx, opts) {
      leaveRooms();

      return new Promise((resolve, reject) => {
        store.commit('blockParticipations.setContext', { type: 'block', id: opts.blockId })
        Vue.http.get(config.root + '/blocks/' + opts.blockId + '/participations').then((result) => {
          store.commit('blockParticipations.setItems', result.data);
          joinRooms()

          resolve(result);
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "blockParticipations.setContext": function(state, context) {
      Vue.set(state, 'context', context)
    },
    "blockParticipations.setItems": function(state, data) {
      Vue.set(state, 'items', data);
      Vue.set(state, 'loaded', true);
    },
    "blockParticipation.update": function(state, data) {
      const blockParticipations = filter(state.items, function(item) {
        return item.id !== data.id;
      });
      blockParticipations.push(data);
      Vue.set(state, 'items', blockParticipations);
    },
    "blockParticipation.remove": function(state, id) {
      const blockParticipations = filter(state.items, function(item) {
        return item.id !== id;
      });
      Vue.set(state, 'items', blockParticipations);
    }
  };

  function leaveRooms() {
    if (state.context) {
      socket.emit('leave', 'exercise:' + state.context.id)
      store.commit('blockParticipations.setContext', null)
    }
  }

  function joinRooms() {
    if (state.context?.id) {
      socket.emit('join', 'blockParticipation:' + state.context.id)
    }
  }

  function listener() {
    socket.on('connect', function () {
      joinRooms()
    })

    socket.on('blockParticipation.update', function(result) {
      store.commit('blockParticipation.update', result.data)
    })

    socket.on('blockParticipation.delete', function(result) {
      store.commit('blockParticipation.remove', result.data)
    })
    socket.emit('join', 'blockParticipation.delete')
  }

  store.registerModule('blockParticipations', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
