import Vue from 'vue';
import socket from 'client/socket';
import config from 'client/config';

const filter = require('lodash/filter');

export default function (store) {
  let state = {
    items: [],
    userId: null,
    disciplineId: null,
    formats: [],
    tieBreaks: [],
  };

  const actions = {
    'importTemplates.load': function(ctx, opts) {
      if (! opts.userId) {
        console.log('no user id');
        return null;
      }

      leaveRooms();
      let params = {};
      store.commit('importTemplates.setUserId', opts.userId);
      if (opts.disciplineId) {
        store.commit('importTemplates.setDisciplineId', opts.disciplineId);
        params = {
          disciplineId: opts.disciplineId,
        };
      }

      return new Promise((resolve, reject) => Vue.http.get(
        config.root + '/seUsers/' + opts.userId + '/templates', {params}).then(result => {
          store.commit('importTemplates.setItems', result.data);
          joinRooms()
          Vue.http.get(config.root + '/disciplines/' + opts.disciplineId + '/get-info',
            {params: { userId: opts.userId}}).then((result) => {
            store.commit('importTemplates.setFormats', result.data.formats);
            store.commit('importTemplates.setTieBreaks', result.data.tieBreaks);
            resolve();
          });
        }, err => reject(err)));
    },
    'importTemplate.update': function(ctx, data) {
      return new Promise((resolve, reject) => Vue.http.patch(config.root + '/importTemplates/' + data.id,
        data).then(result => {
        store.commit('importTemplate.update', result.data);
        resolve(result.data);
      }, err => reject(err)));
    },
    'importTemplate.add': function(ctx, opts) {
      let data = opts.data;
      data.owner_id = ctx.state.userId;
      return new Promise((resolve, reject) =>
        Vue.http.post(config.root + '/seUsers/' + data.owner_id + '/importTemplates', data).then((result) => {
        resolve(result.data);
      }, err => reject(err)));
    },
    'importTemplate.remove': function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/importTemplates/' + opts.id).then(() => {
          store.commit('importTemplate.remove', opts.id);
          resolve();
        }, err => reject(err));
      });
    },
  };

  function leaveRooms() {
    if (state.eventId) {
      socket.emit('leave', 'importTemplate:' + state.userId);
      store.commit("importTemplates.setUserId", undefined);
    }
  }

  function joinRooms() {
    if (state.userId) {
      socket.emit('join', 'importTemplate:' + state.userId)
    }
  }

  const mutations = {
    'importTemplates.setItems': function(state, items) {
      Vue.set(state, 'items', items)
    },
    'importTemplates.setUserId': function(state, userId) {
      Vue.set(state, 'userId', userId);
    },
    'importTemplates.setDisciplineId': function(state, disciplineId) {
      Vue.set(state, 'disciplineId', disciplineId);
    },
    'importTemplates.setFormats': function(state, items) {
      Vue.set(state, 'formats', items)
    },
    'importTemplates.setTieBreaks': function(state, items) {
      Vue.set(state, 'tieBreaks', items)
    },
    'importTemplate.remove': function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      });
    },
    'importTemplate.add': function(state, displayTemplate) {
      state.items.push(displayTemplate);
    },
    'importTemplate.update': function(state, item) {
      const index = state.items.findIndex(i => i.id === item.id);
      let templates = state.items;
      if (index >= 0) {
        templates.splice(index, 1, item);
      }
      else {
        templates.push(item);
      }

      Vue.set(state, 'items', templates);
    }
  };

  function listener() {
    socket.on('connect', function () {
      joinRooms()
    })
    socket.on('importTemplate.update', function(result) {
      console.log('importTemplate update', result);
      if (state.disciplineId && state.disciplineId !== result.data.disciplineId) {
        return;
      }
      store.commit('importTemplate.update', result.data);
    });
    socket.on('importTemplate.delete', result => {
      store.commit('importTemplate.remove', result.data);
    });
    socket.emit('join', 'importTemplate.delete');
  }

  store.registerModule('importTemplates', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
