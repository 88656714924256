import Vue from 'vue';
import socket from "client/socket";
import config from "client/config";

const filter = require("lodash/filter");

export default function (store) {
  let state = {
    items: [],
    userId: null,
  };

  const actions = {
    "planningDefaults.load": function(ctx, opts) {
      if (! opts.userId) {
        console.log('no user id');
        return null;
      }

      leaveRooms();
      store.commit('planningDefaults.setUserId', opts.userId);

      return new Promise((resolve, reject) => Vue.http.get(
        config.root + '/seUsers/' + opts.userId + '/planningDefaults').then(result => {
        store.commit("planningDefaults.setItems", result.data);
        joinRooms()
        resolve();
      }, err => reject(err)));
    },
    "planningDefault.update": function(ctx, opts) {
      return new Promise((resolve, reject) => Vue.http.patch(
        config.root + '/planningDefaults/' + opts.id,
        opts).then(result => {
        store.commit("planningDefault.update", result.data);
        resolve(result.data);
      }, err => reject(err)));
    },
    "planningDefault.add": function(ctx, opts) {
      let data = opts.data;
      data.owner_id = ctx.state.userId;
      return new Promise((resolve, reject) => Vue.http.post(
        config.root + '/seUsers/' + ctx.state.userId + '/planningDefaults', data).then((result) => {
          store.commit('planningDefault.update', result.data)
          resolve(result.data);
      }, err => reject(err)));
    },
    "planningDefault.remove": function(ctx, opts) {
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/planningDefaults/' + opts.id).then(() => {
          store.commit('planningDefault.remove', opts.id);
          resolve();
        }, err => reject(err));
      });
    },
  };

  function leaveRooms() {
    if (state.userId) {
      socket.emit('leave', 'planningDefault:' + state.userId);
      store.commit("users.setUserId", undefined);
    }
  }

  function joinRooms() {
    if (state.userId) {
      socket.emit('join', 'planningDefault:' + state.userId)
    }
  }

  const mutations = {
    "planningDefaults.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "planningDefaults.setUserId": function(state, userId) {
      Vue.set(state, 'userId', userId);
    },
    "planningDefault.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      });
    },
    "planningDefault.update": function(state, planningDefault) {
      const index = state.items.findIndex(i => i.id === planningDefault.id);
      let planningDefaults = state.items;
      if (index >= 0) {
        planningDefaults.splice(index, 1, planningDefault);
      }
      else {
        planningDefaults.push(planningDefault);
      }

      Vue.set(state, 'items', planningDefaults);
    }
  };

  function listener() {
    socket.on('connect', function () {
      joinRooms()
    });
    socket.on('planningDefault.update', function(result) {
      store.commit('planningDefault.update', result.data);
    });
    socket.on('planningDefault.delete', result => {
      store.commit('planningDefault.remove', result.data);
    });
    socket.emit('join', 'planningDefault.delete');
  }

  store.registerModule('planningDefaults', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
