import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");

export default function(store) {
  const state = {
    items: [],
    roles: [],
    roleMappings: [],
  };

  const actions = {
    'users.get': function() {
      socket.emit('leave', 'seUsers');

      return new Promise((resolve, reject) => {
        Promise.all([
          Vue.http.get(config.root + '/seUsers').then(result => {
            store.commit('users.setItems', result.data);
            setRooms()
          }, err => reject(err)),
          Vue.http.get(config.root + '/roles').then(result => {
            store.commit('users.setRoles', result.data);
          }, err => reject(err)),
          Vue.http.get(config.root + '/roleMappings').then(result => {
            store.commit('users.setRoleMappings', result.data);
          }, err => reject(err)),
        ]).then(results => resolve(results)).catch(err => reject(err));
      });
    },
    'customer.add': function(ctx, user) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/seUsers/addCustomer', {data: user}).then(result => {
          store.commit('user.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    'user.add': function(ctx, user) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/seUsers', user).then(result => {
          store.commit('user.update', result.data);
          resolve(result.data);
        }, err => reject(err));
      });
    },
    'user.remove': function(state, userId) {
      store.commit('user.remove', userId);
      return new Promise((resolve, reject) => {
        Vue.http.delete(config.root + '/seUsers/' + userId).then(() => {
          resolve();
        }, err => reject(err));
      });
    }
  };

  const mutations = {
    "users.setItems": function(state, items) {
      Vue.set(state, 'items', items)
    },
    "users.setRoles": function(state, items) {
      Vue.set(state, 'roles', items)
    },
    "users.setRoleMappings": function(state, items) {
      Vue.set(state, 'roleMappings', items)
    },
    "user.remove": function(state, id) {
      state.items = filter(state.items, function(item) {
        return item.id !== id;
      })
    },
    "user.update": function(state, user) {
      const index = state.items.findIndex(i => i.id === user.id);
      let users = state.items;

      if (index >= 0) {
        users.splice(index, 1, user);
      }
      else {
        users.push(user);
      }

      Vue.set(state, 'items', users);
    }
  };

  function setRooms() {
    if (state.items.length) {
      socket.emit('join', 'seUsers')
    }
  }

  function listener() {
    socket.on('connect', function () {
      setRooms()
    })
    socket.on('seUser.update', function(result) {
      if (result.type === 'update') {
        store.commit('user.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('user.update', result.data);
      }
    });
    socket.on('seUser.delete', result => {
      store.commit('user.remove', result.data);
    });
    socket.emit('join', 'seUser.delete');
  }

  store.registerModule('users', {
    state: state,
    mutations: mutations,
    actions: actions
  });

  listener();
}
