import Vue from 'vue';
import socket from 'client/socket';
import config from 'client/config';
import filter from "lodash/filter";

export default function (store) {
  const state = {
    items: [],
    importData: [],
    eventDisciplineId: null,
  };

  const actions = {
    'imports.get': function (ctx, opts) {
      return new Promise((resolve, reject) => {
        if (ctx.state.eventDisciplineId) {
          socket.emit('leave', 'import:' + ctx.state.eventDisciplineId);
        }
        store.commit('imports.setEventDisciplineId', opts.eventDisciplineId);
        Vue.http.get(config.root + '/eventDisciplines/' + opts.eventDisciplineId + '/imports').then(
          (result) => {
            store.commit('imports.setItems', result.data);
            joinRooms()
            resolve(result);
          },
          (err) => reject(err)
        );
      });
    },
    'import.load': function (ctx, importId) {
      if (ctx.state.importData.length) {
        socket.emit('leave', 'importData:' + ctx.state.importData[0].importId)
      }
      return new Promise((resolve, reject) => {
        Vue.http.get(config.root + '/imports/' + importId + '/importData').then(
          (result) => {
            store.commit('importData.setItems', result.data)
            socket.emit('join', 'importData:' + importId)
            resolve(result);
          },
          (err) => reject(err)
        );
      });
    },
    'import.add': function (ctx, data) {
      return new Promise((resolve, reject) => {
        data.eventDisciplineId = ctx.state.eventDisciplineId;
        Vue.http.post(config.root + '/eventDisciplines/' + data.eventDisciplineId + '/addImport', { data: data }).then(
          (result) => {
            store.commit('import.update', result.data.import);
            resolve(result.data.import);
          },
          (err) => reject(err)
        );
      });
    },
    'import.execute': function (ctx, importId) {
      return new Promise((resolve, reject) => {
        Vue.http.post(config.root + '/imports/' + importId + '/execute').then(
          (result) => {
            store.commit('import.update', result.data.import);
            resolve(result.data.import);
          },
          (err) => reject(err)
        );
      });
    },
    'import.delete': function (ctx, opts) {
      return Vue.http.delete(config.root + '/imports/' + opts.importId);
    },
  };

  const mutations = {
    'imports.setEventDisciplineId': function (state, eventDisciplineId) {
      Vue.set(state, 'eventDisciplineId', eventDisciplineId);
    },
    'imports.setItems': function (state, items) {
      Vue.set(state, 'items', items)
    },
    'import.remove': function (state, id) {
      const items = state.items.filter(i => i.id !== id)
      Vue.set(state, 'items', items)
    },
    'import.update': function (state, importItem) {
      const items = filter(state.items, i => i.id !== importItem.id)
      items.push(importItem)
      Vue.set(state, 'items', items)
    },
    'importData.setItems': function (state, items) {
      Vue.set(state, 'importData', items)
    },
    'importData.remove': function(state, id) {
      Vue.set(state, 'importData', state.importData.filter(i => i.id !== id))
    },
    'importData.update': function(state, item) {
      const items = state.importData.filter(i => i.id !== item.id)
      items.push(item)
      Vue.set(state, 'importData', items)
    },
  }

  function joinRooms() {
    if (state.eventDisciplineId) {
      socket.emit('join', 'import:' + state.eventDisciplineId)
    }
  }

  function listener() {
    socket.on('connect', function () {
      if (state.eventDisciplineId) {
        joinRooms()
      }
    })

    socket.on('import.update', function (result) {
      if (result.type === 'update') {
        store.commit('import.update', result.data);
        return;
      }
      if (result.type === 'create') {
        store.commit('import.update', result.data);
      }
    });

    socket.on('import.delete', function (result) {
      store.commit('import.remove', result.data);
    })

    socket.on('importData.update', function (result) {
      if (result.type === 'update') {
        store.commit('importData.update', result.data)
      }
    })

    socket.emit('join', 'import.delete')
  }

  store.registerModule('imports', {
    state: state,
    mutations: mutations,
    actions: actions,
  });

  listener();
}
