import Vue from 'vue';
import config from 'client/config';
import socket from 'client/socket';

const filter = require("lodash/filter");
const find = require("lodash/find");
const forEach = require("lodash/forEach");
const includes = require("lodash/includes");

const scoreHandler = require('lib/scoreHandler.js');
import panelHandler from 'client/lib/panelHandler.js';
import exerciseLib from 'client/lib/exercise.js';

import {min} from 'mathjs';

export default function(store) {
  let state = {
    panelId: undefined,
    panelMemberId: undefined,
    exercise: undefined,
    pass: 1,
    exerciseType: undefined,
    exerciseConfiguration: undefined,
    pages: [],
    inputFinished: false,
    chair: false,
    timer: false,
    fallTimer: false,
    currentPage: 0,
    trackRequirements: false,
  }

  const actions = {
    "exercise.load": function (ctx, opts) {
      if (ctx.state.exercise) {
        socket.emit('leave', 'exercise:' + ctx.state.exercise.id)
      }

      store.commit('exercise.setPanelMember', opts)

      return new Promise((resolve, reject) => {
        let exercise = find(store.state.exercises.items, item => item.id === opts.exerciseId)

        if (exercise) {
          store.commit('exercise.setPass', opts.pass)
          store.commit('exercise.update', exercise)
          store.commit('exercise.setConfiguration', exerciseLib.configuration(exercise))
          store.commit('exercise.setType', find(store.state.eventDiscipline.exerciseTypes, item =>
            item.id === exercise.exerciseTypeId
          ))
          joinRooms()
          initPages()

          resolve(exercise)
        }
        else {
          Vue.http.get(config.root + '/exercises/' + opts.exerciseId + '/load').then(result => {
            store.commit('exercise.setPass', opts.pass)
            store.commit('exercise.update', result.data.exercise)
            store.commit('exercise.setConfiguration', result.data.configuration)
            store.commit('exercise.setType', result.data.exerciseType)
            joinRooms()
            initPages()

            resolve(result.data)
          }, err => reject(err))
        }
      });
    },
    "exercise.setPass": function(ctx, pass) {
      if (pass !== ctx.state.pass) {
        store.commit('exercise.setPass', pass)
        initPages()
      }
    },
    "exercise.setScoreValue": function(ctx, opts) {
      const exerciseId = opts.exerciseId ? opts.exerciseId : ctx.state.exercise.id
      scoreQueue(exerciseId, [{
        type: 'value',
        properties: {
          leg: opts.leg,
          group: opts.group,
          element: opts.element,
          position: opts.position,
          index: opts.index
        },
        value: opts.value
      }])
    },
    'exercise.emitCounter': function(ctx, opts) {
      opts.panelId = ctx.state.panelId
      opts.exerciseId = ctx.state.exercise.id
      console.log('counter emit counter update', opts)
      socket.emit('counter.set', opts)
    },
    "exercise.setNoTeam": function(ctx, opts) {
      scoreQueue(opts.exerciseId, [{
        type: 'noTeam',
        noTeam: opts.noTeam,
      }])
    },
    "exercise.setJoker": function(ctx, opts) {
      scoreQueue(opts.exerciseId, [{
        type: 'joker',
        joker: opts.joker,
      }])
    },
    "exercise.setTimer": function(ctx, opts) {
      scoreQueue(ctx.state.exercise.id, [{
        type: 'timer',
        pass: ctx.state.pass,
        timer: opts.timer,
      }])
    },
    "exercise.confirmValues": function(ctx) {
      scoreQueue(ctx.state.exercise.id, [{
        type: 'confirm',
        pass: state.pass,
        positions: getPanelMember().positions,
      }])
    },
    "exercise.openValues": function(ctx, opts) {
      const value = opts.scoreValue
      scoreQueue(ctx.state.exercise.id, [{
        type: 'openValues',
        pass: state.pass,
        position: value.position,
        group: value.group,
      }])
    },
    "exercise.openAllValues": function(ctx) {
      scoreQueue(ctx.state.exercise.id, [{
        type: 'openAllValues',
        pass: state.pass,
      }])
    },
    "exercise.clearValues": function(ctx, opts) {
      let exerciseId
      if (opts.exerciseId) {
        exerciseId = opts.exerciseId
      }
      else {
        exerciseId = ctx.state.exercise.id
      }
      scoreQueue(exerciseId, [{
        type: 'clearValues',
        status: opts.status,
        pass: opts.pass
      }])
    },

    "exercise.cancel": function(ctx, opts) {
      if (!ctx.state.exercise) {
        return
      }

      const exerciseId = opts.exerciseId ? opts.exerciseId : ctx.state.exercise.id
      return new Promise((resolve) => {
        Promise.all([
          Vue.http.post(config.root + '/exercises/' + exerciseId + '/cancel-edits',
            {pass: ctx.state.pass, nopanel: opts.nopanel}),
          new Promise((resolve, reject) => {
            if (opts.nopanel) { resolve(true) }
            else {
              store.dispatch('panel.setStatus', {
                panelId: ctx.state.panelId,
                newStatus: 'idle-block',
                pass: ctx.state.pass,
                cancelled: true,
              }).then(() => {
                resolve(true)
              }, err => reject(err))
            }
          })
        ]).then(resolve(true))
      })
    },

    "exercise.finishPass": function(ctx, opts) {
      const exerciseId = opts.exerciseId ? opts.exerciseId : ctx.state.exercise.id
      let newStatus = 'finished'
      const pass = ctx.state.pass
      let panelPatch = {
        panelId: ctx.state.panelId,
        newStatus: 'idle-block',
        pass: ctx.state.pass
      }

      if (opts.nextPass) {
        newStatus = 'open'
        panelPatch.newStatus = 'exercise'
        panelPatch.pass += 1
        panelPatch.exerciseId = exerciseId
      }

      return new Promise((resolve, reject) => {
        scoreQueue(exerciseId, [{
          type: 'publishPass',
          status: newStatus,
          pass: pass
        }]).then(() => {
          if (opts.nopanel) {
            store.dispatch('exercise.setPass', pass + 1);
            resolve();
          }
          else {
            store.dispatch('panel.setStatus', panelPatch).then(() => {
              resolve();
            });
          }
        }, err => reject(err));
      });
    },

    "exercise.setPage": function(ctx, opts) {
      store.commit('exercise.setCurrentPage', opts.page)
    },
    "exercise.nextPage": function(ctx) {
      let currentPage = ctx.state.currentPage
      while (++currentPage < ctx.state.pages.length) {
        if (! ctx.state.pages[currentPage].confirmed && ! (ctx.state.pages[currentPage].type === 'total')) {
          break
        }
      }
      store.commit('exercise.setCurrentPage', currentPage);
    },
    "exercise.previousPage": function(ctx) {
      let currentPage = ctx.state.currentPage
      while (--currentPage >= 0) {
        if (! ctx.state.pages[currentPage].confirmed && ! (ctx.state.pages[currentPage].type === 'total')) {
          store.commit('exercise.setCurrentPage', currentPage)
          return
        }
      }
    },
  }

  const mutations = {
    "exercise.setPanelMember": function(state, ids) {
      Vue.set(state, 'panelId', ids.panelId)
      Vue.set(state, 'panelMemberId', ids.panelMemberId)
    },
    "exercise.setPass": function(state, pass) {
      Vue.set(state, 'pass', pass)
    },
    "exercise.update": function(state, item) {
      Vue.set(state, "exercise", item)
    },
    "exercise.setParticipation": function(state, item) {
      Vue.set(state, "participation", item)
    },
    "exercise.setConfiguration": function(state, item) {
      Vue.set(state, 'exerciseConfiguration', item)
    },
    "exercise.setType": function(state, item) {
      Vue.set(state, "exerciseType", item)
    },
    "exercise.setChair": function(state, chair) {
      Vue.set(state, "chair", chair)
    },
    "exercise.setTimer": function(state, timer) {
      Vue.set(state, "timer", timer)
    },
    "exercise.setFallTimer": function(state, timer) {
      Vue.set(state, "fallTimer", timer)
    },
    "exercise.setTrackRequirements": function(state, track) {
      Vue.set(state, "trackRequirements", track)
    },
    "exercise.setPages": function(state, pages) {
      Vue.set(state, 'pages', pages)
    },
    "exercise.setCurrentPage": function(state, page) {
      Vue.set(state, 'currentPage', page)
    },
    "exercise.setPageComplete": function(state, opts) {
      Vue.set(state.pages[opts.index], 'confirmed', opts.confirmed)
      Vue.set(state.pages[opts.index], 'filledOut', opts.filledOut)
    },
    "exercise.setEnabled": function(state, opts) {
      let scoreValue = filterScoreValue(state, opts.scoreValue)
      if (scoreValue) {
        scoreValue.enabled = opts.enabled
      }
    },
    "exercise.setInputFinished": function(state, inputFinished) {
      Vue.set(state, 'inputFinished', inputFinished)
    }
  }

  function filterScoreValue(state, scoreValue) {
    const group = scoreValue.properties.group;
    const element = scoreValue.properties.element;
    const position = scoreValue.properties.position;
    const groupValues = filter(state.pages, item => {
      return group === item.group && position === item.position;
    })[0];
    const elementValues = filter(groupValues.elements, item => {
      return element === item.element;
    })[0];

    if (! elementValues) {
      return null;
    }

    return find(elementValues.values, item => {
      return scoreHandler.equalProperties(item.properties, scoreValue.properties);
    });
  }

  function scoreQueue(exerciseId, updates) {
    return Vue.http.post(config.root + '/queue/exercise', {
      id: exerciseId,
      updates: updates
    });
  }

  function getPanelMember() {
    const panel = find(store.state.panels.items, item => { return item.id === state.panelId; });
    return find(panel.members, item => { return item.id === state.panelMemberId });
  }

  function initPages() {
    let pages = []

    const panel = find(store.state.panels.items, item => item.id === state.panelId)
    if (! panel) {
      return;
    }
    const member = find(panel.members, item => item.id === state.panelMemberId)

    if (! member || ! state.exercise) {
      return
    }

    const panelType = find(store.state.eventDiscipline.panelTypes, item => item.id === panel.panelTypeId )

    const positions = member.positions
    const panelPositions = panelHandler.calculatePositions(panel, panelType)

    const calculationType = panelHandler.getCalculationType(panelType, state.exerciseConfiguration)

    store.commit("exercise.setChair", includes(positions, panelType.chairPosition))
    store.commit("exercise.setTimer", includes(positions, panelType.timerPosition))
    store.commit("exercise.setFallTimer", includes(positions, panelType.fallTimerPosition))
    store.commit("exercise.setTrackRequirements", includes(positions, panelType.requirementsPosition))

    if (state.timer || state.fallTimer) {
      pages.push({
        type: 'timer',
        group: 'timer',
        position: panelType.timerPosition,
        name: Vue.i18n.t('score.timer'),
        complete: false,
      })
    }

    forEach(positions, position => {
      const panelPosition = panelPositions[position]
      forEach(panelPosition.valueTypes, valueType => {
        const scoreGroup = find(calculationType.scoreGroups, group => group.key === valueType)
        if (scoreGroup) {
          if (scoreGroup.multipage) {
            forEach(scoreGroup.elements, element => {
              let valueGroup = {
                type: 'group',
                group: scoreGroup.key,
                element: element.key,
                judgeTotal: {
                  enabled: true,
                  valueType: element.valueType
                },
                position: position,
                name: panelPosition.name,
                multiple: (scoreGroup.elements.length > 1),
                rosterLines: 'subElements',
                filledOut: false,
                confirmed: false,
                elements: [],
              }
              let groupIndex = 0

              forEach(element.subElements, subElement => {
                let valueElement = {
                  element: subElement.key,
                  multiple: false,
                  valueType: subElement.valueType,
                  values: [],
                }

                valueElement.values.push({
                  order: groupIndex++,
                  properties: {
                    leg: state.pass,
                    group: scoreGroup.key,
                    element: subElement.key,
                    position: position,
                    index: 0,
                  },
                  valueType: subElement.valueType,
                  enabled: true,
                })

                valueGroup.elements.push(valueElement)
              })
              pages.push(valueGroup)
            })
            pages.push({
              type: 'total',
              group: scoreGroup.key,
              valueType: scoreGroup.judgeTotal.valueType,
              properties: {
                leg: state.pass,
                group: scoreGroup.key,
                element: 'total',
                index: -1,
                position: position,
              },
              position: position,
              name: panelPosition.name,
              rosterLines: 'subElements',
              filledOut: false,
              confirmed: false,
              elements: [],
            })
          }
          else {
            let valueGroup = {
              type: 'group',
              group: scoreGroup.key,
              judgeTotal: scoreGroup.judgeTotal,
              position: position,
              name: panelPosition.name,
              multiple: (scoreGroup.elements.length > 1),
              rosterLines: scoreGroup.rosterLines,
              filledOut: false,
              confirmed: false,
              elements: []
            }
            let groupIndex = 0

            forEach(scoreGroup.elements, element => {
              let valueElement = {
                element: element.key,
                multiple: element.count > 1,
                countLabels: element.countLabels,
                valueType: element.valueType,
                values: [],
              }

              if (element.valueType.inputType === 'counter') {
                valueGroup.type = 'counter'
              }

              if (valueElement.multiple) {
                valueGroup.multiple = true
              }

              let count = element.count;
              const countFilter = element.valueType.countFilter
              if (countFilter) {
                // check if element count is overruled by the exercise configuration.
                const key = countFilter.group + '.' + countFilter.element + '.max'
                if (state.exerciseConfiguration[key]) {
                  count = min(count, state.exerciseConfiguration[key])
                }
              }

              for (let i = 0; i < count; i++) {
                valueElement.values.push({
                  order: groupIndex++,
                  properties: {
                    leg: state.pass,
                    group: scoreGroup.key,
                    element: element.key,
                    position: position,
                    index: i,
                  },
                  valueType: element.valueType,
                  enabled: true
                })
              }

              valueGroup.elements.push(valueElement)
            })

            pages.push(valueGroup)
          }
        }
      })
    })

    store.commit("exercise.setPages", pages)
    updatePages()

    // init currentPage:
    let i = 0;
    for (; i < state.pages.length; i++) {
      if (! state.pages[i].filledOut) {
        break
      }
    }
    store.commit("exercise.setCurrentPage", i)
  }

  function updatePages() {
    if (! state.exercise ||! state.panelId) {
      return
    }
    const panel = find(store.state.panels.items, item => item.id === state.panelId)

    const panelType = find(store.state.eventDiscipline.panelTypes, item => item.id === panel.panelTypeId)

    let allComplete = true

    forEach(state.pages, (valueGroup, index) => {
      let groupFilledOut = true
      let groupConfirmed = true
      let groupComplete = true

      if (valueGroup.type === 'timer') {
        const exerciseTimer = find(state.exercise.timer, item => item.leg === state.pass)

        groupFilledOut = (exerciseTimer && exerciseTimer.status === 'finished')
        groupConfirmed = false
      }
      else if (valueGroup.type !== 'total') {
        forEach(valueGroup.elements, valueElement => {
          // evaluate count filter
          let countFilter = valueElement.valueType.countFilter
          let refValue = null
          let filterValue = null
          let paramMin = 0, paramMax = 9999
          if (countFilter) {
            const refElement = {
              group: countFilter.group,
              element: countFilter.element,
              position: panelType.chairPosition,
              index: 0,
              leg: state.pass
            }
            refValue = scoreHandler.exerciseGetValue(state.exercise, refElement)

            console.log(countFilter.type)
            if (refValue && countFilter.type === 'equal') {
              const key = countFilter.group + '.' + countFilter.element + '.max'
              filterValue = countFilter.value
              if (state.exerciseConfiguration[key]) {
                filterValue = state.exerciseConfiguration[key]
              }
            } else if (countFilter.type === 'param') {
              const key = countFilter.name
              if (state.exerciseConfiguration[key + '.min']) {
                paramMin = state.exerciseConfiguration[key + '.min']
              }
              if (state.exerciseConfiguration[key + '.max']) {
                paramMax = state.exerciseConfiguration[key + '.max']
              }
            }
          }

          forEach(valueElement.values, value => {
            let enabled = true

            // evaluate count filter
            if (countFilter) {
              if (refValue) {
                if (countFilter.type === 'follow') {
                  enabled = refValue.editValue > value.properties.index
                } else if (countFilter.type === 'equal') {
                  enabled = refValue.editValue >= filterValue
                }
              }
              else if (countFilter.type === 'param') {
                enabled =  paramMin <= value.properties.index && value.properties.index < paramMax
              }
              store.commit("exercise.setEnabled", {
                scoreValue: value,
                enabled: enabled,
              })
            }

            // apply for value updates
            const exerciseValue = scoreHandler.exerciseGetValue(state.exercise, value.properties)

            if (enabled && (!exerciseValue || !exerciseValue.confirmed)) {
              groupConfirmed = false
              groupComplete = false
            }
            if (enabled && (!exerciseValue || ! exerciseValue.editValue === undefined)) {
              groupFilledOut = false
            }
          })
        })
      }

      store.commit("exercise.setPageComplete", {
        index: index,
        confirmed: groupConfirmed,
        filledOut: groupFilledOut,
      })

      if (! groupComplete) {
        allComplete = false
      }
    });

    store.commit('exercise.setInputFinished', allComplete)
  }

  function joinRooms() {
    if (state.exercise) {
      socket.emit('join', 'exercise:' + state.exercise.id)
    }
  }

  function listener() {
    socket.on('connect', function () {
      joinRooms()
    })

    socket.on('exercise.update', function(result) {
      if (result.type === "remove") {
        //remove not supported
        return
      }

      if (! state.exercise) {
        return;
      }
      if (state.exercise.id !== result.data.id) {
        return
      }

      store.commit('exercise.update', result.data)
      updatePages()
    })
  }

  store.registerModule('exercise', {
    state: state,
    mutations: mutations,
    actions: actions
  })

  listener()
}
